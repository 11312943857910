import { CreateActionParams } from '../actions';
import { CouponsErrorType } from '../../../../types/errors';
import { FormStatus } from '../../../../utils/state/initialStateFactory';

export type RemoveCoupon = (clearErrors?: boolean) => void;

export function createRemoveCouponAction({
  getControllerState,
  internalActions,
}: CreateActionParams): RemoveCoupon {
  return async (clearErrors = true) => {
    const [, setState] = getControllerState();
    const { errorHandlers, calculatePaymentDetails } = internalActions;

    if (clearErrors) {
      errorHandlers.clearErrorByTypes([CouponsErrorType]);
    }
    setState({
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    await calculatePaymentDetails({ removeCoupon: true });
  };
}
