import {
  bookingsApplyPromoCode,
  bookingsDeletePromoCode,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { CouponsErrorType } from '../../../../types/errors';
import { FormBiLogger } from '../../../../utils/bi/biLoggerFactory';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';
import { CouponDetails } from '@wix/ambassador-checkout-server/types';

export type CalculatePaymentDetails = ({
  coupon,
  removeCoupon,
  email,
}: {
  coupon?: string;
  removeCoupon?: boolean;
  email?: string;
}) => void;

export function createCalculatePaymentDetailsAction({
  actionFactoryParams,
  errorHandlers,
}: {
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>;
  errorHandlers: ErrorHandlers;
}): CalculatePaymentDetails {
  return async ({ coupon, removeCoupon, email }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi, reportError, biLogger } = actionFactoryParams.context;
    const {
      formInputs: { numberOfParticipants },
      slotAvailability,
      service,
    } = state;
    const { id: serviceId, rate } = service;
    const slot = slotAvailability?.slot!;
    const couponCode = removeCoupon
      ? undefined
      : coupon || state.couponInfo.appliedCoupon?.couponCode;
    try {
      const paymentsDetails = await formApi.getPaymentsDetails({
        slot,
        numberOfParticipants,
        rate,
        serviceId,
        couponCode,
        email,
      });
      const appliedCoupon = paymentsDetails?.couponDetails;
      const finalPrice = paymentsDetails?.finalPrice;
      if (removeCoupon) {
        biLogger?.report(
          bookingsDeletePromoCode({
            couponId: state.couponInfo.appliedCoupon?.couponId,
            couponName: state.couponInfo.appliedCoupon?.couponName,
          }),
        );
      } else {
        ReportOnApplyCouponBIEvent({ biLogger, appliedCoupon });
      }
      setState({
        couponInfo: {
          ...state.couponInfo,
          appliedCoupon,
        },
        paymentDetails: {
          ...state.paymentDetails,
          minCharge: finalPrice?.downPayAmount
            ? Number(finalPrice.downPayAmount)
            : state.paymentDetails.minCharge,
          totalPrice: finalPrice?.amount
            ? Number(finalPrice?.amount)
            : state.paymentDetails.price,
        },
      });
    } catch (error) {
      errorHandlers.addError(error);
      reportError(error);
      ReportOnApplyCouponBIEvent({ biLogger, errorMessage: error });
    }
    setState({
      status: FormStatus.IDLE,
    });
  };
}

const ReportOnApplyCouponBIEvent = ({
  biLogger,
  appliedCoupon,
  errorMessage,
}: {
  biLogger?: FormBiLogger;
  appliedCoupon?: CouponDetails;
  errorMessage?: CouponsErrorType;
}) => {
  const couponId = appliedCoupon?.couponId;
  const couponName = appliedCoupon?.couponName;
  const valid_value = errorMessage ? 'invalid' : 'ok';
  biLogger?.report(
    bookingsApplyPromoCode({
      valid_value,
      couponId,
      couponName,
      errorMessage,
    }),
  );
};
